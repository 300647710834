import React from "react"
import { Link } from "gatsby"
import BlogStyles from "./header.module.scss"
import headerStyles from "./header.module.scss"

const HeaderBebidas = () => {
  return(
    <nav className={BlogStyles.all}>
    <ul className={BlogStyles.navList}>
      <li>
        <Link
            className={headerStyles.navItem}
            activeClassName={headerStyles.activeNavItem}
          to="/agua"
        >
          Water
        </Link>
      </li> 
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/aguadecoco"
        >
          Coconut Water
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/refrigerante"
        >
          Soda
        </Link>
      </li>  
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/suco"
        >
          Juice
        </Link>
      </li> 
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/chapronto"
        >
          Iced Tea
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/isotonico"
        >
          Isotonic
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/cerveja"
        >
          Beer
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/destilado"
        >
          Distillate
        </Link>
      </li>
    </ul>
  </nav>
  )
}
export default HeaderBebidas